import { useState, useContext, useEffect } from "react";
import { XummContext } from "../../../../lib/context/xumm";
import test from "../../../../assets/Placeholder-Image.png";
import { IconDuplicate, IconUser } from "../../../../icons";
import { toAddressSubstring } from "../../../../lib/helpers";
import { UserItems } from "./items";
import { UserActivity } from "./activity";
import { Event, Token } from "../../../../shared/types";
import { ClaimModal } from "./claim-modal";

type ProfileProps = {
  user: {
    address: string;
    image: string;
    items: Array<Token>;
  };
  setLoginModalOpen: (value: boolean) => void;
};

export const ProfileCard: React.FC<ProfileProps> = ({
  user,
  setLoginModalOpen,
}) => {
  const { userState, disconnectWallet } = useContext(XummContext);
  const [activity, setActivity] = useState<Array<Event>>([]);
  const [isItems, setIsItems] = useState(true);
  const [claimModalOpen, setClaimModalOpen] = useState(false);
  const [userImage, setUserImage] = useState("");

  const closeModal = () => {
    setClaimModalOpen(false);
  };

  const onDisconnectWalletHandler = () => {
    //@ts-ignore
    disconnectWallet();
  };

  const setProfileImage = async (image: string) => {
    if (userState?.jwt && image !== userImage) {
      try {
        const res = await fetch(
          `https://api.spacemermaids.xyz/marketplace/user`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: userState.jwt,
            },
            body: JSON.stringify({
              image,
            }),
          }
        );
        const { success } = await res.json();
        if (success) {
          setUserImage(image);
        }
      } catch {}
    }
  };

  const getUserActivity = async () => {
    try {
      const res = await fetch(
        `https://api.spacemermaids.xyz/marketplace/useractivity?address=${user.address}`
      );
      const parsed = await res.json();
      setActivity((prevActivity) => {
        const activity = [...parsed, ...prevActivity];
        const seenIds = new Set();
        const uniqueEvents = activity.filter((event) => {
          if (seenIds.has(event.id)) {
            return false;
          }
          seenIds.add(event.id);
          return true;
        });
        return uniqueEvents;
      });
    } catch {}
  };

  useEffect(() => {
    getUserActivity();
  }, []);

  useEffect(() => {
    if (user.image !== "") {
      setUserImage(user.image);
    }
  }, [user]);

  return (
    <div className="user-layout">
      <div className="user-profile-wrapper">
        <div
          className={
            userImage !== ""
              ? "user-profile-img-wrapper"
              : "user-profile-img-wrapper none"
          }
        >
          <img
            src={userImage !== "" ? userImage : test}
            className="user-profile-img"
            width={180}
            height={180}
          />
        </div>
        <div className="user-profile-info-wrapper">
          <div className="user-profile-info-heading">
            <div
              className="id-wrapper"
              onClick={() => {
                navigator.clipboard.writeText(user.address);
              }}
            >
              <div className="text-size-small">
                {toAddressSubstring(user.address)}
              </div>
              <IconDuplicate />
            </div>
            {user.items.length > 0 &&
              user.address === userState?.user.userAddress && (
                <button
                  onClick={() => setClaimModalOpen(true)}
                  type="button"
                  className="id-wrapper"
                  disabled={user.items.length == 0}
                >
                  <IconUser />
                  <div className="text-size-small">Claim Profile</div>
                </button>
              )}
          </div>
          {userState && userState.user.userAddress == user.address && (
            <button
              type="button"
              onClick={onDisconnectWalletHandler}
              className="id-wrapper-centered"
            >
              <div className="text-size-small">Disconnect Wallet</div>
            </button>
          )}
        </div>
      </div>
      <div className="user-feed">
        <div className="user-feed-selection">
          <div className="user-feed-selection-tags">
            <button
              onClick={() => {
                setIsItems(true);
              }}
              className={
                isItems ? "selection-tag is-underline" : "selection-tag"
              }
            >
              Items
            </button>
            <button
              onClick={() => {
                setIsItems(false);
              }}
              className={
                !isItems ? "selection-tag is-underline" : "selection-tag"
              }
            >
              Activity
            </button>
          </div>
        </div>
        <div className="user-feed-header">
          <div className="user-tags-wrapper">
            <div className="user-tag">
              <div className="text-size-small">
                {isItems ? "User Items" : "User Activity"}
              </div>
            </div>
          </div>
        </div>
        {isItems ? (
          <UserItems items={user.items} setLoginModalOpen={setLoginModalOpen} />
        ) : (
          <UserActivity activity={activity} />
        )}
      </div>
      <ClaimModal
        open={claimModalOpen}
        closeModal={closeModal}
        tokens={user.items}
        setProfileImage={setProfileImage}
      />
    </div>
  );
};
