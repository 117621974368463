type MobileNavProps = {
  onClickHandler: () => void;
};

export const MobileNav: React.FC<MobileNavProps> = ({ onClickHandler }) => {
  return (
    <div className="mobile-nav" onClick={onClickHandler}>
      <div className="menu-icon">
        <div className="menu-icon-top"></div>
        <div className="menu-icon-middle">
          <div className="menu-icon-middle-innder"></div>
        </div>
        <div className="menu-icon-bottom"></div>
      </div>
    </div>
  );
};
