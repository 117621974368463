import { useLoaderData } from "react-router-dom";
import { Profile } from "../../shared/components/profile";
import { User } from "../../shared/types/user";

type AccountProps = {
  data: User | null;
};

export const Account: React.FC<AccountProps> = ({ data }) => {
  return <Profile user={data as User} />;
};
