import { IconDelete } from "../../../../icons";

type ModalHeaderProps = {
  children: any;
  closeModal: () => void;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  closeModal,
}) => {
  return (
    <div className="marketplace--modal-header">
      <div>{children}</div>
      <button
        className="marketplace--modal-header-close"
        onClick={closeModal}
        type="button"
        aria-label="close"
      >
        <IconDelete />
      </button>
    </div>
  );
};
