import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import navbarLogo from "../../../assets/SM-Logo-TP-black.png";
import discordButton from "../../../assets/discordbuttonv2.png";
import twitterButton from "../../../assets/twitterbutton-sameshine.png";
import { XummContext } from "../../../lib/context/xumm";
import { WalletModal } from "./wallet-modal";
import { toAddressSubstring } from "../../../lib/helpers";
import loaderAnimation from "../../../lotties/loader.json";
import Lottie from "react-lottie";
import { useWindowDimension } from "../../../hooks/useWindow";
import { MobileNav } from "./mobile-nav";
import { MobileOverlay } from "./mobile-overlay";

type NavbarProps = {
  txModalOpen: boolean;
  loginModalOpen: boolean;
  setTxModalOpen: () => void;
  setLoginModalOpen: (value: boolean) => void;
  disconnectUnity?: () => void;
  unload?: () => Promise<void>;
};

export const Navbar: React.FC<NavbarProps> = ({
  txModalOpen,
  loginModalOpen,
  setLoginModalOpen,
  setTxModalOpen,
  disconnectUnity,
  unload,
}) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { width } = useWindowDimension();
  const { connectWallet, disconnectWallet, userState, pendingPayload } =
    useContext(XummContext);
  const navigate = useNavigate();
  const location = useLocation();

  const closeModal = () => {
    setLoginModalOpen(false);
  };

  const navHandler = async (to: string) => {
    if (location.pathname === to && to === "/") {
      return;
    }
    await unloadHandler();
    navigate(to);
  };

  const onWalletButtonClick = () => {
    if (userState) {
      //@ts-ignore
      navHandler("/account");
    } else {
      //@ts-ignore
      connectWallet().then(setLoginModalOpen(true));
    }
  };

  const unloadHandler = async () => {
    try {
      if (unload !== undefined) {
        await unload();
      }
    } catch {}
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
  };

  const onMobileNavClick = () => {
    setMobileNavOpen((prev) => {
      return !prev;
    });
  };

  return (
    <div className="site--naviation-w-nav">
      <div className="site--navigation">
        <span
          className="site--navigation-logo w-nav-brand"
          onClick={() => navHandler("/")}
        >
          <img src={navbarLogo} width={108} />
        </span>
        <div className="site--navigation-link-socials-button-container">
          <nav className="site--navigation-link-container">
            <MobileNav onClickHandler={onMobileNavClick} />
            <a
              className="site--navigation-link"
              href="https://spacemermaids.xyz"
            >
              HOME
            </a>
            <span
              className="site--navigation-link"
              onClick={() => navHandler("/faq")}
            >
              FAQ
            </span>
            <span
              className="site--navigation-link"
              onClick={() => navHandler("/")}
            >
              GAME
            </span>
            <span
              className="site--navigation-link"
              onClick={() => navHandler("/claim")}
            >
              CLAIM
            </span>
            {/* <span
              className="site--navigation-link"
              onClick={() => navHandler("/trade")}
            >
              TRADE
            </span> */}
          </nav>
          <div className="site--navigation-socials-container">
            <a
              href="https://discord.gg/s4pQnw5D7d"
              className="site--navigation-socials-link"
            >
              <img
                src={discordButton}
                width={width < 910 ? 30 : 40}
                height={width < 910 ? 30 : 40}
              />
            </a>
            <a
              href="https://twitter.com/spacemermaids"
              className="site--navigation-socials-link"
            >
              <img
                src={twitterButton}
                width={width < 910 ? 30 : 40}
                height={width < 910 ? 30 : 40}
              />
            </a>
          </div>
          <div className="site--navigation-connect-wallet">
            {width > 910 && (
              <button
                className="site--navigation-connect-wallet-btn"
                onClick={onWalletButtonClick}
              >
                {userState === undefined
                  ? "Connect Wallet"
                  : `Welcome, ${toAddressSubstring(
                      userState.user.userAddress
                    )}`}
              </button>
            )}
            {pendingPayload !== undefined && txModalOpen === false && (
              <button
                className="site--navigation-pending-tx-button square"
                onClick={setTxModalOpen}
              >
                <Lottie
                  options={lottieOptions}
                  width={"2rem"}
                  height={"2rem"}
                  isClickToPauseDisabled
                />
              </button>
            )}
          </div>
        </div>
      </div>
      <WalletModal open={loginModalOpen} closeModal={closeModal} />
      <MobileOverlay isOpen={mobileNavOpen} />
    </div>
  );
};
