export const IconRipple: React.FC = () => {
  return (
    <svg
      fill="#FFF"
      width="16px"
      height="16px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M27.401 19.531c-1.131-0.645-2.407-0.837-3.672-0.885-1.052-0.031-2.631-0.724-2.631-2.645 0-1.432 1.156-2.588 2.647-2.645 1.265-0.048 2.541-0.24 3.671-0.891 3.193-1.844 4.292-5.928 2.448-9.125-1.859-3.199-5.952-4.287-9.156-2.437-2.072 1.187-3.348 3.401-3.339 5.787 0 1.296 0.464 2.484 1.052 3.599 0.496 0.927 0.735 2.661-0.948 3.635-1.265 0.724-2.843 0.272-3.624-0.989-0.661-1.068-1.459-2.063-2.589-2.708-3.197-1.849-7.291-0.751-9.124 2.437-1.839 3.199-0.745 7.281 2.452 9.125 2.068 1.187 4.609 1.187 6.677 0 1.125-0.647 1.923-1.641 2.584-2.708 0.541-0.871 1.911-1.985 3.624-0.991 1.267 0.719 1.657 2.319 0.948 3.641-0.583 1.093-1.052 2.297-1.052 3.593 0 3.688 2.991 6.672 6.677 6.677 3.688 0 6.672-2.989 6.677-6.677 0.011-2.385-1.255-4.599-3.323-5.792z" />
    </svg>
  );
};
