export const toAddressSubstring = (address: string) => {
  return `${address.substring(0, 6)}...${address.substring(30, 34)}`;
};

export const formattedElapsedTime = (startTime: number) => {
  const elapsedTimeString = getElapsedTime(startTime).split(":");
  let formattedTime = "";
  for (let i = 0; i < elapsedTimeString.length; i++) {
    if (elapsedTimeString[i] !== "0") {
      if (i === 0) {
        formattedTime += `${elapsedTimeString[i]}d `;
      } else if (i === 1) {
        formattedTime += `${elapsedTimeString[i]}h `;
      } else if (i === 2) {
        formattedTime += `${elapsedTimeString[i]}m `;
      }
    }
  }
  if (formattedTime === "") {
    formattedTime += "0m";
  }
  return `${formattedTime} ago`;
};

const getElapsedTime = (startTime: number) => {
  // Record end time
  let endTime = Date.now();

  // Compute time difference in milliseconds
  let timeDiff = endTime - startTime;

  // Convert time difference from milliseconds to seconds
  timeDiff = timeDiff / 1000;

  // Extract integer seconds that dont form a minute using %
  let seconds = Math.floor(timeDiff % 60); //ignoring uncomplete seconds (floor)

  // Pad seconds with a zero if neccessary
  let secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";

  // Convert time difference from seconds to minutes using %
  timeDiff = Math.floor(timeDiff / 60);

  // Extract integer minutes that don't form an hour using %
  let minutes = timeDiff % 60; //no need to floor possible incomplete minutes, becase they've been handled as seconds

  // Pad minutes with a zero if neccessary
  let minutesAsString = minutes < 10 ? `${minutes}` : minutes + "";

  // Convert time difference from minutes to hours
  timeDiff = Math.floor(timeDiff / 60);

  // Extract integer hours that don't form a day using %
  let hours = timeDiff % 24; //no need to floor possible incomplete hours, becase they've been handled as seconds

  // Convert time difference from hours to days
  timeDiff = Math.floor(timeDiff / 24);

  // The rest of timeDiff is number of days
  let days = timeDiff;

  let totalHours = hours; //+ (days * 24); // add days to hours
  let totalHoursAsString = totalHours < 10 ? `${totalHours}` : totalHours + "";
  let totalDaysAsString = days < 10 ? `${days}` : days + "";

  return `${totalDaysAsString}:${totalHoursAsString}:${minutesAsString}`;
};
