import { useState, ReactNode } from "react";
import { Analytics } from "@vercel/analytics/react";
import { useXumm } from "../hooks/useXumm";
import { XummContext } from "../lib/context/xumm";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import {
  PageLanding,
  PageToken,
  PageGame,
  PageFaq,
  PageClaim,
  PageAccount,
  PageUser,
  PageNotFound,
} from "../pages";

const Provider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [alertMessage, setAlertMessage] = useState("");
  const xumm = useXumm(setAlertMessage);
  return <XummContext.Provider value={xumm}>{children}</XummContext.Provider>;
};

export const App: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: "/claim",
      element: <PageClaim />,
    },
    // {
    //   path: "/trade",
    //   element: <PageLanding />,
    // },
    // {
    //   path: "/token/:tokenId",
    //   element: <PageToken />,
    //   loader: async ({ params }) => {
    //     const res = await fetch(
    //       `https://api.spacemermaids.xyz/marketplace/token?tokenId=${params.tokenId}`
    //     );
    //     if (res.status !== 200) {
    //       throw new Response("Token Not Found.", { status: 404 });
    //     }
    //     const parsed = await res.json();
    //     if (!parsed.tokenId) {
    //       throw new Response("Token Not Found.", { status: 404 });
    //     }
    //     return parsed;
    //   },
    //   shouldRevalidate: () => false,
    //   errorElement: <PageNotFound />,
    // },
    {
      path: "/faq",
      element: <PageFaq />,
    },
    {
      path: "/",
      element: <PageGame />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "/user/:userAddress",
      element: <PageUser />,
      loader: async ({ params }) => {
        return fetch(
          `https://api.spacemermaids.xyz/marketplace/user?address=${params.userAddress}`
        );
      },
    },
    {
      path: "/account",
      element: <PageAccount />,
      loader: async () => {
        return null;
      },
    },
  ]);

  return (
    <Provider>
      <div className="page-wrapper">
        <div className="background-color-black">
          <RouterProvider router={router} />
          <Analytics />
        </div>
      </div>
    </Provider>
  );
};
