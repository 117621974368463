import { useState, useEffect, useContext } from "react";
import { XummContext } from "../../lib/context/xumm";
import { Navbar } from "../../shared/components";
import test from "../../assets/testimage.png";
import { MermaidCard } from "./mermaid-card";
import { Countdown } from "../../shared/components/countdown";
import { TransactionModal } from "../../shared/components/transaction-modal";
import { NavLink } from "react-router-dom";

export const Claim: React.FC = () => {
  const [txModalOpen, setTxModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [claimableMermaids, setClaimableMermaids] = useState<
    | undefined
    | { quantity: number; mermaids: Array<{ offer: string; claimed: boolean }> }
  >();
  const { userState } = useContext(XummContext);

  const openTxModal = () => {
    setTxModalOpen(true);
  };

  const closeTxModal = () => {
    setTxModalOpen(false);
  };

  const getClaimStatus = async (address: string) => {
    try {
      const res = await fetch(
        `https://api.spacemermaids.xyz/mint/status?address=${address}`
      );
      const parsed = await res.json();
      setClaimableMermaids(parsed);
    } catch {}
  };

  useEffect(() => {
    if (userState !== undefined) {
      const intervalId = setInterval(() => {
        getClaimStatus(userState.user.userAddress);
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [userState]);

  useEffect(() => {
    if (userState !== undefined) {
      getClaimStatus(userState.user.userAddress);
    }
  }, [userState]);

  const getContent = () => {
    if (userState === undefined) {
      return (
        <>
          <div className="site--claim-section-claim-wrapper-text">
            <p className="text-size-large">
              Connect your wallet to see your rewards.
            </p>
          </div>
        </>
      );
    } else if (
      claimableMermaids !== undefined &&
      claimableMermaids.quantity !== 0
    ) {
      return (
        <>
          <div className="site--claim-section-claim-wrapper-text">
            <p className="text-size-large">
              Congrats!
              <br />
              <br />
              You won {claimableMermaids.quantity} Space Mermaid NFT(s) by
              placing in the top 500 scores in the play-to-mint gaming
              tournament.
            </p>
          </div>
          {claimableMermaids.mermaids.length === 0 && (
            <>
              <p className="text-size-large">
                Claim Begins in <Countdown date={1691071945000} />
              </p>
            </>
          )}
          {claimableMermaids.mermaids.map((mermaid: any) => {
            return (
              <MermaidCard mermaid={mermaid} setTxModalOpen={setTxModalOpen} />
            );
          })}
          <p className="text-size-small light">
            Note: Tokens are reserved and need to be claimed within 60 days.
            After 60 days your rewards will expire.
          </p>
        </>
      );
    } else if (
      claimableMermaids !== undefined &&
      claimableMermaids.quantity === 0
    ) {
      return (
        <div className="site--claim-section-claim-wrapper-text">
          <p className="text-size-large">
            Oh no!
            <br />
            <br />
            You did not win a Space Mermaid NFT.
          </p>
        </div>
      );
    } else {
      return (
        <div className="site--claim-section-claim-wrapper-text">
          <p className="text-size-large">
            Hold Tight!
            <br />
            <br />
            Checking your results.
          </p>
        </div>
      );
    }
  };

  return (
    <main className="site--claim">
      <Navbar
        txModalOpen={txModalOpen}
        setTxModalOpen={openTxModal}
        loginModalOpen={loginModalOpen}
        setLoginModalOpen={setLoginModalOpen}
      />
      <section className="site--claim-section">
        <div className="site--claim-section-claim">
          <div className="site--claim-section-claim-header">
            <h2 className="site--claim-section-claim-header-heading">
              Claim Your Rewards
            </h2>
          </div>
          <div className="site--claim-section-claim-wrapper">
            {getContent()}
          </div>
        </div>
      </section>
      <TransactionModal open={txModalOpen} closeModal={closeTxModal} />
    </main>
  );
};
