//@ts-ignore
import safemath from "safemath";

export const getCurrentFormattedDateTime = (offsetMs?: number) => {
  const finalDate = offsetMs ? Date.now() + offsetMs : Date.now();
  const date = new Date(finalDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed in JavaScript
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const dateToRippleEpoch = (date: number) => {
  const seconds = Math.floor(+safemath.safeDiv(date, "1000"));
  const rippleEpochTime = safemath.safeSub(seconds.toString(), "946684800");
  return rippleEpochTime;
};

export const rippleEpochToDate = (date: number) => {
  const standardEpochTime = safemath.safeAdd(date.toString(), "946684800");
  const inms = safemath.safeMule(standardEpochTime, "1000");
  return inms;
};

export const timeUntil = (targetUnixEpochMs: number) => {
  const targetUnixEpoch = targetUnixEpochMs / 1000;
  const now = Date.now() / 1000; // Current time in seconds
  const secondsUntil = targetUnixEpoch - now;

  if (secondsUntil <= 0) {
    return "The time has already passed or is now.";
  }

  const days = Math.floor(secondsUntil / (24 * 60 * 60));
  const hours = Math.floor((secondsUntil % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((secondsUntil % (60 * 60)) / 60);
  const seconds = Math.floor(secondsUntil % 60);

  if (days > 0) {
    return `${days} ${days === 1 ? "day" : "days"}`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? "hour" : "hours"}`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
  } else {
    return `${seconds} ${seconds === 1 ? "second" : "seconds"}`;
  }
};
