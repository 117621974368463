import { NavLink } from "react-router-dom";
import logo from "../../../assets/sm-ragzy-logo.png";
import twitter from "../../../assets/twitterbutton-sameshine-p-130x130q80.png";
import discord from "../../../assets/discordbuttonv2.png";

export const Footer: React.FC = () => {
  return (
    <section className="site--footer">
      <div className="site--footer-container">
        <div className="site--footer-container-grid">
          <img src={logo} width={123} />
          <div className="site--footer-container-grid-navlist">
            <span>NAVIGATION</span>
            <a href="https://spacemermaids.xyz">HOME</a>
          </div>
          <div className="site--footer-container-grid-socials">
            <span>FOLLOW US</span>
            <a href="https://discord.gg/s4pQnw5D7d">
              <img src={discord} height={32} width={32} />
              Discord
            </a>
            <a href="https://twitter.com/spacemermaids">
              <img src={twitter} height={32} width={32} />
              Twitter
            </a>
          </div>
        </div>
        <div className="site--footer-ragzy-info">
          Ragzy is a Ripple Creator Fund Recipient.
          <br />
          Copyright © Space Mermaids 2023. All Rights Reserved.
          <br />
          <a
            href="https://www.spacemermaids.xyz/terms-of-service"
            className="termslink"
          >
            TERMS OF SERVICE
          </a>
          {" - "}
          <a
            href="https://www.spacemermaids.xyz/privacy-policy"
            className="termslink"
          >
            PRIVACY POLICY
          </a>
          {" - "}
          <a href="https://www.spacemermaids.xyz/rules" className="termslink">
            RULES
          </a>
        </div>
        <div className="site--footer-zilla-info">
          a project produced by{" "}
          <a className="zilla-link" href="https://zilla.gg/">
            ZILLA
          </a>
        </div>
      </div>
      <div className="site--footer-background-image-wrapper"></div>
    </section>
  );
};
