import { useState, useContext } from "react";
import { XummContext } from "../../../lib/context/xumm";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "../../../shared/components";
import { Token } from "../../../shared/types";
import { getCurrentFormattedDateTime } from "../../../shared/utils/date";
import ReactSwitch from "react-switch";

type ListTokenModalProps = {
  tokenData: Token;
  open: boolean;
  closeModal: () => void;
  openTxModal: () => void;
};

export const ListTokenModal: React.FC<ListTokenModalProps> = ({
  tokenData,
  open,
  closeModal,
  openTxModal,
}) => {
  const [privateIsChecked, setPrivateIsChecked] = useState(false);
  const [listPrice, setListPrice] = useState(0);
  const [expiryDate, setExpiryDate] = useState(
    getCurrentFormattedDateTime(500000)
  );
  const [privateAddress, setPrivateAddress] = useState("");
  const [expiryError, setExpiryError] = useState(false);
  const [privateAddressError, setPrivateAddressError] = useState(false);
  const { listToken, userState } = useContext(XummContext);

  const onCheckHandler = (value: boolean) => {
    if (!value) {
      setPrivateAddress("");
    }
    setPrivateIsChecked(value);
  };

  const onListPriceChange = (event: any) => {
    if (typeof +event.target.value == "number" && +event.target.value >= 0) {
      setListPrice(+event.target.value);
    }
  };

  const onExpiryChange = (event: any) => {
    if (new Date(event.target.value).valueOf() >= Date.now() + 300000) {
      setExpiryError(false);
      setExpiryDate(event.target.value);
    } else {
      setExpiryError(true);
    }
  };

  const onPrivateAddressChange = (event: any) => {
    if (event.target.value.length < 35) {
      setPrivateAddress(event.target.value);
    }
  };

  const onListButtonClick = () => {
    if (tokenData.owner !== userState?.user.userAddress) {
      return;
    }
    if (new Date(expiryDate).valueOf() <= Date.now() + 300000) {
      setExpiryError(true);
      return;
    }
    if (
      (privateIsChecked && privateAddress.length < 25) ||
      privateAddress.length > 35
    ) {
      setPrivateAddressError(true);
      return;
    }
    //@ts-ignore
    listToken(
      tokenData.tokenId,
      listPrice.toString(),
      new Date(expiryDate).valueOf(),
      privateAddress
    ).then(openTxModal);
    closeModal();
    setExpiryError(false);
    setListPrice(0);
    setPrivateAddressError(false);
    setPrivateAddress("");
    setPrivateIsChecked(false);
  };

  const closeModalHandler = () => {
    closeModal();
    setListPrice(0);
    setExpiryError(false);
    setPrivateAddress("");
    setPrivateAddressError(false);
    setPrivateIsChecked(false);
  };

  return (
    <Modal open={open} closeModal={closeModalHandler}>
      <ModalHeader closeModal={closeModalHandler}>
        <span>{`List ${tokenData.name}`}</span>
      </ModalHeader>
      <ModalContent>
        <div className="list-token-modal-content">
          <img src={tokenData.image} height={300} width={300} />
          <div className="list-token-modal-content-form">
            <div className="list-token-modal-content-form-input private">
              <span>Price (XRP)</span>
              <input
                type="text"
                value={listPrice}
                onChange={onListPriceChange}
                placeholder="Listing Price"
              />
            </div>
            <div className="list-token-modal-content-form-input">
              <span>Expires On</span>
              <input
                min={getCurrentFormattedDateTime(300000)}
                className={expiryError ? "expiry-error" : undefined}
                type="datetime-local"
                value={expiryDate}
                onChange={onExpiryChange}
              />
            </div>
          </div>
          <div className="list-token-modal-content-form private">
            <div className="list-token-modal-content-form-input switch">
              <span>Private Listing</span>
              <ReactSwitch
                checked={privateIsChecked}
                onChange={onCheckHandler}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#af68ff"
              />
            </div>
            <div
              className="list-token-modal-content-form-input private"
              style={{ display: privateIsChecked ? "flex" : "none" }}
            >
              <span>Destination Address</span>
              <input
                className={privateAddressError ? "expiry-error" : undefined}
                type="text"
                value={privateAddress}
                size={30}
                maxLength={35}
                onChange={onPrivateAddressChange}
              />
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className="list-token-modal-footer">
          <button
            className="product-header-token-actions-button islight"
            onClick={onListButtonClick}
          >
            List Token
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
