import { Event } from "../../../../types";
import { UserActivityRow } from "./activity-row";

type UserActivityProps = {
  activity: Array<Event>;
};

export const UserActivity: React.FC<UserActivityProps> = ({ activity }) => {
  return (
    <section className="section-activity">
      <div className="container-large">
        <div className="padding-xsmall">
          <table className="marketplace-body-activity-table">
            <thead className="marketplace-body-activity-table-head">
              <tr className="marketplace-body-activity-table-head-row">
                <td className="marketplace-activity-event">Event</td>
                <td className="marketplace-activity-item">Item</td>
                <td className="marketplace-activity-price">Price</td>
                <td className="marketplace-activity-from">From</td>
                <td className="marketplace-activity-to">To</td>
                <td className="marketplace-activity-time">Time</td>
              </tr>
            </thead>

            <tbody className="marketplace-body-activity-table-body">
              {activity.length > 0 ? (
                activity.map((action) => {
                  return <UserActivityRow action={action} />;
                })
              ) : (
                <tr className="token-events-table-row-empty">No Past Events</tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};
