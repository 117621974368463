import { createContext } from "react";
import { Bid, Event, Listing, Token } from "../../shared/types";

export type XummContextType = {
  userState?: {
    user: { userAddress: string; userToken: string };
    jwt: string;
    exp: number;
  };
  pendingLogin?: { payload: string; qrCodeUrl: string };
  pendingPayload?: {
    uuid: string;
    state:
      | "created"
      | "expired"
      | "cancelled"
      | "app opened"
      | "pushed"
      | "signed"
      | "confirmed"
      | "failed"
      | "dispatched";
    txnId: string;
  };
  cancelPayload?: () => Promise<unknown>;
  connectWallet?: () => void;
  disconnectWallet?: () => void;
  acceptBid?: (bid: Bid) => Promise<unknown>;
  mintByPrivateListing?: (sellOffer: string) => Promise<unknown>;
  bidToken?: (
    tokenId: string,
    amount: string,
    owner: string,
    expiry: number
  ) => Promise<unknown>;
  buyToken?: (listing: Listing) => Promise<unknown>;
  cancelOffer?: (
    offers: Array<Listing | Bid>,
    tokenId: string
  ) => Promise<unknown>;
  listToken?: (
    tokenId: string,
    amount: string,
    expiry: number,
    destination?: string
  ) => Promise<unknown>;
  getTokens?: (params: any) => Promise<Array<Token>>;
  getToken?: (tokenId: string) => Promise<Token>;
  getActivity?: (
    query: {
      sales: boolean;
      listings: boolean;
      offers: boolean;
    },
    after: string
  ) => Promise<Array<Event>>;
};

export const XummContext = createContext<XummContextType>({});
