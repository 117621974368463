import { useState, useContext, useEffect } from "react";
import Lottie from "react-lottie";
import { XummContext } from "../../../../lib/context/xumm";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "../../modal";
import { toAddressSubstring } from "../../../../lib/helpers";
import loaderAnimation from "../../../../lotties/loader.json";

type WalletModalProps = {
  open: boolean;
  closeModal: () => void;
};

export const WalletModal: React.FC<WalletModalProps> = ({
  open,
  closeModal,
}) => {
  const { pendingLogin, userState } = useContext(XummContext);
  const [status, setStatus] = useState("Waiting For Login");

  useEffect(() => {
    if (pendingLogin === undefined) {
      if (userState) {
        setStatus(
          `Connected: ${toAddressSubstring(userState.user.userAddress)}`
        );
        setTimeout(() => {
          closeModal();
          setStatus("Waiting For Login");
        }, 1000);
      } else {
        closeModal();
      }
    }
  }, [pendingLogin]);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
  };

  return (
    <Modal open={open} closeModal={closeModal}>
      <ModalHeader closeModal={closeModal}>
        <span>Connect Xumm Wallet</span>
      </ModalHeader>
      <ModalContent>
        {pendingLogin !== undefined ? (
          <div className="login-content">
            <img src={pendingLogin.qrCodeUrl} height={300} width={300} />
            <span className="terms-subtext">
              By connecting your wallet you agree to our{" "}
              <a
                href="https://www.spacemermaids.xyz/terms-of-service"
                target="_blank"
              >
                terms of service
              </a>
              {", "}
              <a
                href="https://www.spacemermaids.xyz/privacy-policy"
                target="_blank"
              >
                privacy policy
              </a>
              {", and "}
              <a href="https://www.spacemermaids.xyz/rules" target="_blank">
                rules
              </a>
            </span>
            <span className="xumm-subtext">
              Don't have a{" "}
              <a
                href={pendingLogin.qrCodeUrl.split("_q.pn")[0]}
                target="_blank"
              >
                XUMM Wallet
              </a>
              ?
            </span>
          </div>
        ) : (
          <Lottie
            options={lottieOptions}
            width={200}
            height={200}
            isClickToPauseDisabled
          />
        )}
      </ModalContent>
      <ModalFooter>
        <div className="wallet-modal-status-container">
          <span>{status}</span>
        </div>
      </ModalFooter>
    </Modal>
  );
};
