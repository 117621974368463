import { Modal, ModalContent, ModalHeader } from "../../../shared/components";

type FaqModalProps = {
  open: boolean;
  closeModal: () => void;
};

export const FaqModal: React.FC<FaqModalProps> = ({ open, closeModal }) => {
  return (
    <Modal open={open} closeModal={closeModal}>
      <ModalHeader closeModal={closeModal}>F.A.Q</ModalHeader>
      <ModalContent>
        <div className="faq">
          <div className="faq-item">
            <span className="faq-header">1. What is Space Mermaids?</span>
            <span className="faq-content">
              Space Mermaids is a skill based, retro, bizarre, experimental, and
              absolutely badass blockchain game, where players will compete in a
              30-day tournament for high scores. Top scores will win a mint for
              a Space Mermaid NFT (Non-Fungible Token). It’s a
              first-of-its-kind, “play-to-mint” game where players can win an
              NFT from the Space Mermaids limited collection. It was created by
              renowned pixel and Lego artist, Ragzy, and was funded by Ripple
              through their Creator Fund.
            </span>
          </div>
          <div className="faq-item">
            <span className="faq-header">2. What is Play-to-Mint?</span>
            <span className="faq-content">
              Play-to-Mint is a new concept created by Space Mermaids. It
              involves a "reverse mint" process where players engage in the game
              first, attempting to win an NFT with skilled play. This is
              different from games that require you to own an NFT before being
              able to play.
            </span>
          </div>
          <div className="faq-item">
            <span className="faq-header">
              3. Do I need an NFT to play Space Mermaids?
            </span>
            <span className="faq-content">
              No, you do not need an NFT to play the game. Space Mermaids is
              free to play, and anyone can participate without owning an NFT.
            </span>
          </div>
          <div className="faq-item">
            <span className="faq-header">4. How do I connect to the game?</span>
            <span className="faq-content">
              To connect to Space Mermaids, you need a XUMM Wallet. XUMM Wallets
              allow players to securely connect and interact with the game. It’s
              also how your score is tracked and recorded on the leaderboard.
              Watch this tutorial and follow along{" "}
              <a href="https://www.youtube.com/watch?v=SV2IaAPodQ4">here</a>.
            </span>
          </div>
          <div className="faq-item">
            <span className="faq-header">
              5. What happens if I achieve a high score?
            </span>
            <span className="faq-content">
              If you get a high score in Space Mermaids, you will be eligible to
              mint a Space Mermaid from the limited collection. We have not
              disclosed what qualifies as a high enough score, we’re keeping it
              a mystery--for now.
            </span>
          </div>
          <div className="faq-item">
            <span className="faq-header">
              6. What is considered a high score?
            </span>
            <span className="faq-content">
              High scores in Space Mermaids are determined by the players
              themselves. Scores are ranked in numerical order from the highest
              score to the lowest score. We haven’t announced what qualifies as
              a high score to win a space mermaid at this time, however, we are
              doing everything in “reverse” meaning, the MORE PLAYERS that PLAY
              Space Mermaids, the EASIER it will be to win and the more likely
              you will win a mint. So, make sure you spread the word!
            </span>
          </div>
          <div className="faq-item">
            <span className="faq-header">
              7. Is there a cost to play Space Mermaids?
            </span>
            <span className="faq-content">
              No, playing Space Mermaids is completely FREE. There are no costs
              associated with participating in the game. Even if your XUMM is
              “deactivated” it will still connect to the game, and you can still
              place a high score.
            </span>
          </div>
          <div className="faq-item">
            <span className="faq-header">8. Who is Ragzy?</span>
            <span className="faq-content">
              Ragzy is a world-renowned artist known for her block and pixel
              style artwork. She has been involved in the NFT space since 2017.
              Space Mermaids will be her genesis series on the XRPL (XRP
              Ledger). You can read her bio{" "}
              <a href="https://www.ragzyart.com/about-c24vq">here</a>.
            </span>
          </div>
          <div className="faq-item">
            <span className="faq-header">
              9. What blockchain is Space Mermaids built on?
            </span>
            <span className="faq-content">
              Space Mermaids was built on the XRP Ledger (XRPL).
            </span>
          </div>
          <div className="faq-item">
            <span className="faq-header">
              10. Is Space Mermaids a Ripple Creator Fund winner?
            </span>
            <span className="faq-content">
              Yes, Space Mermaids was selected to be part of the Ripple Creator
              Fund and is funded by Ripple.
            </span>
          </div>
          <div className="faq-item">
            <span className="faq-header">
              11. Can I receive a reward for connecting and playing the game
              with a new wallet?
            </span>
            <span className="faq-content">
              Yes, newly created XUMM wallets that connect and play the game may
              be eligible to receive a 10 XRP activation while supplies last.
              Please note that this offer is limited to one wallet per person.
            </span>
          </div>
          <div className="faq-item">
            <span className="faq-header">
              12. How many NFTs are there in Space Mermaids?
            </span>
            <span className="faq-content">This is yet to be announced.</span>
          </div>
          <div className="faq-item">
            <span className="faq-header">
              13. Is the game compatible with my smart phone?
            </span>
            <span className="faq-content">
              The game is currently desktop only. It will not work properly on
              your smart phone. However, You can have the XUMM wallet app on
              your phone and easily scan the QR code for the game on your
              desktop to log in.
            </span>
          </div>
          <div className="faq-item">
            <span className="faq-header">14. When does the game start?</span>
            <span className="faq-content">
              The game began on June 26th and ends on July 25th. Winners will be
              announced after July 25th.
            </span>
          </div>
          <div className="faq-item">
            <span className="faq-header">
              15. Does Space Mermaids have utility?
            </span>
            <span className="faq-content">
              Space Mermaids is an experiment with a “Reverse Mint” meaning the
              utility (game) was released prior to the NFTS. Eligible players
              who win an NFT will get to use their Space Mermaid NFT in the
              game.
            </span>
          </div>
          <span>
            If you have any additional questions or concerns, please feel free
            to <a href="https://discord.gg/s4pQnw5D7d">contact us</a>.
          </span>
        </div>
      </ModalContent>
    </Modal>
  );
};
