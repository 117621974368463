import { useContext } from "react";
import { XummContext } from "../../../../lib/context/xumm";
import { toAddressSubstring } from "../../../../lib/helpers";

type UserCardProps = {
  userState:
    | undefined
    | {
        user: { userAddress: string; userToken: string };
        jwt: string;
        exp: number;
      };
  gameUser:
    | undefined
    | {
        username: string;
        userAddress: string;
        scores: Array<{ score: number; date: string }>;
      };
  setLoginModalOpen: (value: boolean) => void;
};

export const UserCard: React.FC<UserCardProps> = ({
  userState,
  gameUser,
  setLoginModalOpen,
}) => {
  const { connectWallet } = useContext(XummContext);

  const onConnectWalletHandler = () => {
    //@ts-ignore
    connectWallet().then(setLoginModalOpen(true));
  };

  const InnerComponent = () => {
    if (userState === undefined) {
      return (
        <>
          <button
            onClick={onConnectWalletHandler}
            className="button-main islightsmall"
          >
            LOGIN!
          </button>
          <div className="login-component-text-holder">
            <span>New To Space Mermaids?</span>
            <span onClick={onConnectWalletHandler}>Connect</span>
          </div>
        </>
      );
    } else {
      const max =
        gameUser && gameUser.scores.length > 0
          ? gameUser.scores.reduce((prev, current) => {
              return prev.score > current.score ? prev : current;
            })
          : {
              score: 0,
            };
      return (
        <>
          <span>{`Welcome, ${
            gameUser?.username === "" || gameUser?.username === undefined
              ? toAddressSubstring(userState.user.userAddress)
              : gameUser?.username
          }`}</span>
          <div className="loggedin-component-text-holder">
            <span>Highest Score</span>
            <span>{max.score}</span>
          </div>
        </>
      );
    }
  };

  return <div className="site-game-leaderboard-user">{InnerComponent()}</div>;
};
