export const IconUser: React.FC = () => {
  return (
    <svg width="512" height="512" x="0" y="0" viewBox="0 0 512 512">
      <g>
        <path
          d="M244.317 299.051c-90.917 8.218-160.183 85.041-158.976 176.32V480c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32v-5.909c-.962-56.045 40.398-103.838 96-110.933 58.693-5.82 110.992 37.042 116.812 95.735.344 3.47.518 6.954.521 10.441V480c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32v-10.667c-.104-94.363-76.685-170.774-171.047-170.67a171.4 171.4 0 0 0-11.31.388zM256.008 256c70.692 0 128-57.308 128-128S326.7 0 256.008 0s-128 57.308-128 128c.07 70.663 57.337 127.929 128 128zm0-192c35.346 0 64 28.654 64 64s-28.654 64-64 64-64-28.654-64-64 28.654-64 64-64z"
          fill="#ffffff"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};
