import { useContext } from "react";
import { XummContext } from "../../../../../../lib/context/xumm";
import {
  formattedElapsedTime,
  toAddressSubstring,
} from "../../../../../../lib/helpers";
import { Event } from "../../../../../../shared/types";
import { NavLink } from "react-router-dom";

type ActivityRowProps = {
  action: Event;
};

export const UserActivityRow: React.FC<ActivityRowProps> = ({ action }) => {
  const { userState } = useContext(XummContext);
  const { type, tokenSerial, amount, from, to, date, tokenId } = action;
  return (
    <tr className="marketplace-body-activity-table-body-row">
      <td className="marketplace-body-activity-table-body-row-event">
        <span>{type}</span>
      </td>
      <td className="marketplace-activity-item link">
        <NavLink
          to={`/token/${tokenId}`}
        >{`Space Mermaid #${tokenSerial}`}</NavLink>
      </td>
      <td className="marketplace-activity-price">{(+amount).toFixed(2)} XRP</td>
      <td className="marketplace-activity-from">
        {userState?.user.userAddress.toLowerCase() === from.toLowerCase()
          ? "You"
          : toAddressSubstring(from).toUpperCase()}
      </td>
      <td className="marketplace-activity-to">
        {userState?.user.userAddress.toLowerCase() === to.toLowerCase()
          ? "You"
          : toAddressSubstring(to).toUpperCase()}
      </td>
      <td className="marketplace-activity-time">
        {formattedElapsedTime(date)}
      </td>
    </tr>
  );
};
