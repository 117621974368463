type ModalProps = {
  open: boolean;
  children: any;
  closeModal: () => void;
  className?: string;
};

export const Modal: React.FC<ModalProps> = ({ open, children, closeModal, className }) => {
  if (!open) {
    return null;
  }

  return (
    <>
      <div onClick={closeModal} className="site--components--modal-background" />
      <div className={`site--components--modal ${className ? className : ""}`}>{children}</div>
    </>
  );
};
