import { NavLink } from "react-router-dom";
import twitter from "../../../../assets/twitterbutton-sameshine.png";
import discord from "../../../../assets/discordbuttonv2.png";

type MobileOverlayProps = {
  isOpen: boolean;
};

export const MobileOverlay: React.FC<MobileOverlayProps> = ({ isOpen }) => {
  return (
    <div
      className="mobile-overlay"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <nav role="navigation" className="mobile-overlay-menu">
        <div className="mobile-overlay-link-wrapper">
          <a className="mobile-overlay-link" href="https://spacemermaids.xyz/">
            Home
          </a>
          <NavLink className="mobile-overlay-link" to="/faq">
            Faq
          </NavLink>
          <NavLink className="mobile-overlay-link" to="/">
            Game
          </NavLink>
          <NavLink className="mobile-overlay-link" to="/claim">
            Claim
          </NavLink>
          {/* <NavLink className="mobile-overlay-link" to="/trade">
            Trade
          </NavLink> */}
          <div className="mobile-overlay-social-list">
            <a
              className="mobile-overlay-social-link"
              href="https://discord.gg/s4pQnw5D7d"
            >
              <img src={discord} height={40} width={40} alt="discord logo" />
            </a>
            <a
              className="mobile-overlay-social-link"
              href="https://twitter.com/spacemermaids"
            >
              <img src={twitter} height={40} width={40} alt="twitter logo" />
            </a>
          </div>
          <div className="mobile-overlay-button-wrapper">
            <NavLink className="mobile-overlay-play-button" to="/">
              Play Now
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
  );
};
