import Lottie from "react-lottie";
import loaderAnimation from "../../../../lotties/loader.json";

export const UnityLoader: React.FC = () => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
  };
  return (
    <div className="site--unity-loader">
      <Lottie
        options={lottieOptions}
        width={100}
        height={100}
        isClickToPauseDisabled
      />
    </div>
  );
};
