import { useState } from "react";
import { Navbar } from "../../components";
import { ProfileCard } from "./profile-card";
import { User } from "../../types/user";
import { ConnectWallet } from "./connect-wallet";

type ProfileProps = {
  user: User;
};

export const Profile: React.FC<ProfileProps> = ({ user }) => {
  const [txModalOpen, setTxModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const openTxModal = () => {
    setTxModalOpen(true);
  };

  return (
    <main className="site--user">
      <Navbar
        txModalOpen={txModalOpen}
        setTxModalOpen={openTxModal}
        loginModalOpen={loginModalOpen}
        setLoginModalOpen={setLoginModalOpen}
      />
      <section className="site--user-section">
        <div className="padding-top padding-xhuge">
          <div className="container-large">
            {user !== null ? (
              <ProfileCard user={user} setLoginModalOpen={setLoginModalOpen} />
            ) : (
              <ConnectWallet setLoginModalOpen={setLoginModalOpen} />
            )}
          </div>
        </div>
      </section>
    </main>
  );
};
