export const IconDuplicate: React.FC = () => {
  return (
    <svg width="512" height="512" x="0" y="0" viewBox="0 0 512 512">
      <g>
        <path
          d="M437.781 36.885A117.14 117.14 0 0 0 352.64 0H288c-60.623.095-111.219 46.301-116.8 106.667H160C95.228 106.737 42.737 159.228 42.667 224v170.667C42.737 459.439 95.228 511.929 160 512h85.333c64.772-.071 117.263-52.561 117.333-117.333v-11.2c60.37-5.572 106.582-56.173 106.667-116.8V116.693a116.286 116.286 0 0 0-31.552-79.808zM298.667 394.667c0 29.455-23.878 53.333-53.333 53.333H160c-29.455 0-53.333-23.878-53.333-53.333V224c0-29.455 23.878-53.333 53.333-53.333h10.667v96C170.737 331.439 223.228 383.929 288 384h10.667v10.667zm106.666-128C405.333 296.122 381.455 320 352 320h-64c-29.455 0-53.333-23.878-53.333-53.333V117.333C234.667 87.878 258.545 64 288 64h53.333v21.333C341.333 108.897 360.436 128 384 128h21.333v138.667z"
          fill="#ffffff"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};
