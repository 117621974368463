import { Unity } from "react-unity-webgl";
import { UnityProvider } from "react-unity-webgl/distribution/types/unity-provider";
import { UnityLoader } from "./unity-loader";
import { IconFullscreen } from "../../../icons";
import { useWindowDimension } from "../../../hooks/useWindow";
import { MobilePlayer } from "./mobile-player";

type UnityPlayerProps = {
  isLoaded: boolean;
  unityProvider: UnityProvider;
  requestFullScreen: (value: boolean) => void;
  openFaqModal: () => void;
};

export const UnityPlayer: React.FC<UnityPlayerProps> = ({
  unityProvider,
  isLoaded,
  requestFullScreen,
  openFaqModal,
}) => {
  const { width } = useWindowDimension();
  return (
    <div className="site--unity-player">
      <div className="unity-player-overlay-container">
        <div className="site--game-player-wrapper">
          {isLoaded === false && width > 910 && <UnityLoader />}
          {width > 910 || isLoaded ? (
            <Unity
              unityProvider={unityProvider}
              style={{
                width: 947,
                height: 702,
                visibility: isLoaded ? "visible" : "hidden",
              }}
            />
          ) : (
            <MobilePlayer />
          )}
        </div>
      </div>
      {width > 910 && isLoaded && (
        <div className="player-buttons">
          <button className="site--unity-player-button" onClick={openFaqModal}>
            <span>Questions? Read our FAQ</span>
          </button>
          <button
            className="site--unity-player-button"
            onClick={() => requestFullScreen(true)}
          >
            <span>Full Screen</span>
            <IconFullscreen />
          </button>
        </div>
      )}
    </div>
  );
};
