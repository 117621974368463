import { useState, useEffect, useContext } from "react";
import Lottie from "react-lottie";
import { XummContext } from "../../../lib/context/xumm";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "..";
import loaderAnimation from "../../../lotties/loader.json";
import { toAddressSubstring } from "../../../lib/helpers";

type TransactionModalProps = {
  open: boolean;
  closeModal: (lastState?: string) => void;
};

export const TransactionModal: React.FC<TransactionModalProps> = ({
  open,
  closeModal,
}) => {
  const [lastState, setLastState] = useState<string | undefined>();
  const { pendingPayload, cancelPayload } = useContext(XummContext);

  const pendingPayloadOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
  };

  const onCancelPayloadHandler = () => {
    //@ts-ignore
    cancelPayload();
  };

  useEffect(() => {
    if (pendingPayload === undefined) {
      closeModal(lastState);
      setLastState(undefined);
    } else {
      setLastState(pendingPayload.state);
    }
  }, [pendingPayload]);

  return (
    <Modal open={open} closeModal={closeModal}>
      <ModalHeader closeModal={closeModal}>
        <span>Transaction Pending</span>
      </ModalHeader>
      <ModalContent>
        <div className="transaction-modal-content">
          <Lottie
            options={pendingPayloadOptions}
            height={120}
            width={120}
            isClickToPauseDisabled
          />
          <div className="transaction-modal-data-container">
            <div className="transaction-modal-status">
              <span>Status: </span>
              <span>{pendingPayload?.state}</span>
            </div>
            <div className="transaction-modal-status">
              <span>Transaction ID: </span>
              {pendingPayload &&
              pendingPayload.txnId !== "" &&
              pendingPayload.txnId !== null ? (
                <a
                  className="transaction-modal-txn-link"
                  href={`https://livenet.xrpl.org/transactions/${pendingPayload.txnId}`}
                >
                  {toAddressSubstring(pendingPayload.txnId)}
                </a>
              ) : (
                <span>Awaiting Signer</span>
              )}
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className="list-token-modal-footer">
          <button
            className="cancel-payload-button"
            onClick={onCancelPayloadHandler}
            type="button"
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
