import { useContext } from "react";
import { IconRipple } from "../../../../../icons";
import { XummContext } from "../../../../../lib/context/xumm";
import { Token } from "../../../../../shared/types";
//@ts-ignore
import safemath from "safemath";
import { NavLink } from "react-router-dom";
import { fromXrpDrops } from "../../../../../shared/utils";
import { rippleEpochToDate } from "../../../../../shared/utils/date";

type CardProps = {
  token: Token;
  openTxModal: () => void;
  setLoginModalOpen: (value: boolean) => void;
  isUserProfile?: boolean;
};

export const Card: React.FC<CardProps> = ({
  token,
  openTxModal,
  setLoginModalOpen,
  isUserProfile = false,
}) => {
  const { buyToken, userState, connectWallet } = useContext(XummContext);

  const validListings = token.activeListings.filter((offer) => {
    if (offer.destination !== undefined && offer.destination !== null) {
      if (
        offer.creator == token.owner &&
        userState?.user.userAddress == token.owner
      ) {
        return true;
      }
      if (
        userState?.user.userAddress.toLowerCase() ===
          offer.destination.toLowerCase() &&
        offer.creator == token.owner &&
        rippleEpochToDate(offer.expiry || 0) > Date.now()
      ) {
        return true;
      }
      return false;
    }
    return (
      offer.creator === token.owner &&
      rippleEpochToDate(offer.expiry) > Date.now()
    );
  });

  const validOffers = token.activeBids.filter((offer) => {
    if (offer.destination !== undefined && offer.destination !== null) {
      return false;
    }
    return (
      offer.creator !== token.owner &&
      rippleEpochToDate(offer.expiry) > Date.now()
    );
  });

  const cheapestListing =
    validListings.length > 0
      ? validListings.reduce((a, b) => {
          return +a.amount < +b.amount ? a : b;
        })
      : undefined;

  const highestOffer =
    validOffers.length > 0
      ? validOffers.reduce((a, b) => {
          return +a.amount < +b.amount ? b : a;
        })
      : undefined;

  const onBuyNowHandler = async () => {
    if (userState && userState.user.userToken) {
      //@ts-ignore
      buyToken(cheapestListing).then(openTxModal);
    } else {
      //@ts-ignore
      connectWallet().then(setLoginModalOpen(true));
    }
  };

  return (
    <div className="marketplace-body-card">
      <NavLink className="navlink-no-decoration" to={`/token/${token.tokenId}`}>
        <div className="marketplace-body-card-image-wrapper">
          <img
            src={token.image}
            className="marketplace-body-card-image-wrapper-img"
            loading="lazy"
          />
        </div>
      </NavLink>
      <div className="marketplace-body-card-content">
        <NavLink
          className="navlink-no-decoration"
          to={`/token/${token.tokenId}`}
        >
          <div className="margin-bottom margin-xxsmall">
            <div className="text-size-medium">{token.name}</div>
          </div>
        </NavLink>
        <NavLink
          className="navlink-no-decoration"
          to={`/token/${token.tokenId}`}
        >
          <div className="margin-bottom margin-xxsmall">
            <div className="margin-bottom margin-xxsmall">
              <div className="text-size-medium">
                {cheapestListing
                  ? `${fromXrpDrops(cheapestListing.amount)} XRP`
                  : "Unlisted"}
              </div>
            </div>
          </div>
        </NavLink>
        {validListings.length > 0 && !isUserProfile && (
          <div className="marketplace-body-card-button-group margin-bottom-small">
            <button
              className="marketplace-body-card-button-group-button islight"
              onClick={onBuyNowHandler}
              disabled={validListings.length == 0}
            >
              Buy Now
            </button>
            <IconRipple />
          </div>
        )}
        <div className="marketplace-body-card-highest-offer">
          <div className="text-color-gray text-size-small">Highest Offer:</div>
          <div className="text-color-gray text-size-small">
            {highestOffer === undefined
              ? "None"
              : `${fromXrpDrops(highestOffer.amount)} XRP`}
          </div>
        </div>
      </div>
    </div>
  );
};
