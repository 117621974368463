import { NavLink } from "react-router-dom";
import NotFound from "../../assets/not-found.svg";

export const Page404: React.FC = () => {
  return (
    <div className="page--404">
      <div className="page--404-block">
        <img src={NotFound} className="image-404" style={{ fill: "#FFF" }} />
        <h3>Page Not Found</h3>
        <div className="padding-xxsmall"></div>
        <div>The page you are looking for doesn't exist or has been moved</div>
        <div className="padding-xxsmall"></div>
        <NavLink to="/" className="go-home-button">
          Go Home
        </NavLink>
      </div>
    </div>
  );
};
