export const IconFullscreen: React.FC = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      x="0px"
      y="0px"
      viewBox="0 0 298.667 298.667"
    >
      <g>
        <g>
          <g>
            <polygon points="42.667,192 0,192 0,298.667 106.667,298.667 106.667,256 42.667,256 			" />
            <polygon points="0,106.667 42.667,106.667 42.667,42.667 106.667,42.667 106.667,0 0,0 			" />
            <polygon points="192,0 192,42.667 256,42.667 256,106.667 298.667,106.667 298.667,0 			" />
            <polygon points="256,256 192,256 192,298.667 298.667,298.667 298.667,192 256,192 			" />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
