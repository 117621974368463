import { useCallback, useContext, useEffect, useState, useRef } from "react";
import { useUnityContext } from "react-unity-webgl";
import { Navbar } from "../../shared/components";
import { XummContext } from "../../lib/context/xumm";
import { Leaderboard } from "./leaderboard";
import { UnityPlayer } from "./unity-player";
import { JSEncrypt } from "jsencrypt";
import { FaqModal } from "./faq-modal";

const baseUri = "https://api.spacemermaids.xyz";

export const Game: React.FC = () => {
  const [faqModalOpen, setFaqModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [userGameState, setUserGameState] = useState<
    | undefined
    | {
        userAddress: string;
        scores: Array<{ score: number; date: string }>;
        username: string;
        mermaids: Array<any>;
      }
  >();
  const { userState } = useContext(XummContext);
  const userStateRef = useRef(userState);
  const userGameStateRef = useRef(userGameState);
  const {
    unityProvider,
    isLoaded,
    sendMessage,
    requestFullscreen,
    unload,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    loaderUrl:
      "https://pub-a7ea061a3efe4d21be45ccb83e3320ef.r2.dev/Space-Mermaids-Version-2-Halloween-Release-Candidate.loader.js",
    dataUrl:
      "https://pub-a7ea061a3efe4d21be45ccb83e3320ef.r2.dev/Space-Mermaids-Version-2-Halloween-Release-Candidate.data",
    frameworkUrl:
      "https://pub-a7ea061a3efe4d21be45ccb83e3320ef.r2.dev/Space-Mermaids-Version-2-Halloween-Release-Candidate.framework.js",
    codeUrl:
      "https://pub-a7ea061a3efe4d21be45ccb83e3320ef.r2.dev/Space-Mermaids-Version-2-Halloween-Release-Candidate.wasm",
  });

  const closeFaqModal = () => {
    setFaqModalOpen(false);
  };

  const openFaqModal = () => {
    setFaqModalOpen(true);
  };

  const encryptData = (data: any) => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(
      "-----BEGIN PUBLIC KEY-----\n" +
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC70zB5eTlN4uJ0nEMXXVBwHjxH\n" +
        "t8qHBxXpT3Ht+XC8tWiL22BR+9YzWpvpYTQn/r03S/JKSM1eQaI7hohDGHaAvVFR\n" +
        "K9dFo2EYBtr/5S7spOnsFepHEgJ+ExkEFW8Otr5aog0Lij7oWgbW8daAtOWEPjZf\n" +
        "j0D8HheaOPjKAxgsWQIDAQAB\n" +
        "-----END PUBLIC KEY-----"
    );
    const encrypted = encrypt.encrypt(JSON.stringify(data));
    return encrypted;
  };

  const getUserGame = async (address: string) => {
    try {
      const res = await fetch(`${baseUri}/play/user?userAddress=${address}`);
      const parsed = await res.json();
      if (parsed.success) {
        setUserGameState({
          ...parsed.data,
        });
      }
    } catch {}
  };

  //get user on initial page load
  useEffect(() => {
    if (userState?.user.userAddress !== undefined) {
      getUserGame(userState?.user.userAddress);
    }
  }, []);

  const disconnectUnity = () => {
    sendMessage("UnityInterop", "DisconnectWallet");
  };

  const unloadHandler = async () => {
    if (isLoaded) {
      await unload();
    }
  };

  const getData = useCallback(
    (score: string) => {
      if (userGameStateRef.current?.userAddress) {
        return {
          userAddress: userGameStateRef.current.userAddress,
          username: userGameStateRef.current.username,
          score: +score,
        };
      } else if (userStateRef.current?.user.userAddress) {
        return {
          userAddress: userStateRef.current.user.userAddress,
          score: +score,
        };
      }
      return {};
    },
    [userGameStateRef, userStateRef]
  );

  useEffect(() => {
    userStateRef.current = userState;
  }, [userState]);

  useEffect(() => {
    userGameStateRef.current = userGameState;
  }, [userGameState]);

  const handlePostScore = useCallback(
    async (score: string) => {
      const data = getData(score);
      if ("userAddress" in data && userStateRef.current?.jwt) {
        const encryptedScore = encryptData(data);
        await fetch(`${baseUri}/play/postScore`, {
          method: "POST",
          headers: {
            "api-key":
              "d06d0d34ceca9d376ffc4d99a8aad47e050abf7a9864b4499f794fa7d2654d37",
            "content-type": "application/json",
            authorization: userStateRef.current.jwt,
          },
          body: JSON.stringify({
            result: encryptedScore,
          }),
        });
        getUserGame(userStateRef.current.user.userAddress);
      }
    },
    [userGameStateRef, userStateRef]
  );

  useEffect(() => {
    //@ts-ignore
    addEventListener("PostScore", handlePostScore);

    //@ts-ignore
    return () => removeEventListener("PostScore", handlePostScore);
  }, [addEventListener, removeEventListener]);

  useEffect(() => {
    window.addEventListener("beforeunload", unloadHandler);

    return () => window.removeEventListener("beforeunload", unloadHandler);
  }, []);

  return (
    <main className="site--game">
      <Navbar
        txModalOpen={false}
        setTxModalOpen={() => {}}
        loginModalOpen={loginModalOpen}
        setLoginModalOpen={setLoginModalOpen}
        disconnectUnity={disconnectUnity}
        unload={unload}
      />
      <div className="site--game-content-holder">
        <div className="site--game-player-content-holder">
          <UnityPlayer
            openFaqModal={openFaqModal}
            unityProvider={unityProvider}
            isLoaded={isLoaded}
            requestFullScreen={requestFullscreen}
          />
          <Leaderboard
            userGameState={userGameState}
            setLoginModalOpen={() => setLoginModalOpen(true)}
            userState={userState}
          />
        </div>
      </div>
      <FaqModal open={faqModalOpen} closeModal={closeFaqModal} />
    </main>
  );
};
