import { Card } from "../../../../../pages/landing/primary/items/card";
import { Token } from "../../../../../shared/types";

type UserItemsProps = {
  items: Array<Token>;
  setLoginModalOpen: (value: boolean) => void;
};

export const UserItems: React.FC<UserItemsProps> = ({
  items,
  setLoginModalOpen,
}) => {
  return (
    <section className="section-items">
      <div className="container-large">
        <div className="padding-xsmall">
          {items.length > 0 ? (
            <div className="layout-grid-items-list">
              {items.map((item) => {
                return (
                  <Card
                    token={item}
                    openTxModal={() => {}}
                    isUserProfile={true}
                    setLoginModalOpen={setLoginModalOpen}
                  />
                );
              })}
            </div>
          ) : (
            <div className="no-items-container">
              <div className="text-size-small">
                This wallet has not collected any Space Mermaid NFTs
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
