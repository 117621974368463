import { useEffect, useState, useCallback } from "react";
import { XummContextType } from "../lib/context/xumm";
import { XummTypes } from "xumm-sdk";
import { Bid, Listing } from "../shared/types";
import { dateToRippleEpoch } from "../shared/utils/date";

const baseUri = "https://api.spacemermaids.xyz";

export const useXumm = (
  setAlertMessage: (message: string) => void
): XummContextType => {
  const [pendingPayload, setPendingPayload] = useState<
    | undefined
    | {
        uuid: string;
        state:
          | "created"
          | "expired"
          | "cancelled"
          | "app opened"
          | "pushed"
          | "signed"
          | "confirmed"
          | "failed"
          | "dispatched";
        txnId: string;
      }
  >();
  const [pendingLogin, setPendingLogin] = useState<
    undefined | { payload: string; qrCodeUrl: string }
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userState, setUserState] = useState<
    | undefined
    | {
        user: { userToken: string; userAddress: string };
        jwt: string;
        exp: number;
      }
  >();

  // Local storage helpers
  const updateUserState = (
    userState:
      | undefined
      | {
          user: { userToken: string; userAddress: string };
          jwt: string;
          exp: number;
        }
  ) => {
    if (userState !== undefined) {
      window.localStorage.setItem("Auth", JSON.stringify(userState));
      setUserState(userState);
    } else {
      window.localStorage.removeItem("Auth");
      setUserState(undefined);
    }
  };

  // user login
  const getUserState = () => {
    // check if local state is already set
    if (userState && userState.exp > Date.now() / 1000) {
      return userState;
    }
    // if no local state active check local storage
    const auth = window.localStorage.getItem("Auth");
    if (auth) {
      const parsed = JSON.parse(auth);
      if (Date.now() / 1000 > parsed.exp) {
        updateUserState(undefined);
        return undefined;
      } else {
        // restore user state
        setUserState(parsed);
        return parsed;
      }
    }
    return auth;
  };

  const connectWallet = useCallback(async () => {
    try {
      if (pendingLogin) {
        return;
      }
      const user = getUserState();
      if (user) {
        setUserState(user);
      } else {
        setIsLoading(true);
        const response = await fetch(`${baseUri}/users/login`, {
          mode: "cors",
        });
        const parsed = await response.json();
        setPendingLogin({
          payload: parsed.created.uuid,
          qrCodeUrl: parsed.created.refs.qr_png,
        });
      }
    } catch {}
  }, [pendingLogin]);

  const disconnectWallet = useCallback(async () => {
    updateUserState(undefined);
  }, []);

  // marketplace utils
  const getPayloadState = (
    payload: XummTypes.XummGetPayloadResponse
  ):
    | "created"
    | "expired"
    | "cancelled"
    | "app opened"
    | "pushed"
    | "signed"
    | "confirmed"
    | "failed"
    | "dispatched" => {
    if (payload.meta.cancelled) {
      return "cancelled";
    }
    if (payload.meta.expired) {
      return "expired";
    }
    if (payload.response.dispatched_result === "tesSUCCESS") {
      return "confirmed";
    }
    if (
      payload.response.dispatched_to_node &&
      payload.response.dispatched_result !== "tesSUCCESS"
    ) {
      return "failed";
    }
    if (payload.response.dispatched_to_node) {
      return "dispatched";
    }
    if (payload.meta.signed) {
      return "signed";
    }
    if (payload.meta.app_opened) {
      return "app opened";
    }
    if (payload.meta.pushed) {
      return "pushed";
    }
    return "created";
  };

  //payload management

  const cancelPayload = useCallback(async () => {
    if (userState === undefined) {
      setAlertMessage("Connect wallet before transacting.");
    } else if (pendingPayload === undefined) {
      return;
    } else {
      try {
        const res = await fetch(
          `${baseUri}/marketplace/cancel?payload=${pendingPayload.uuid}`,
          {
            headers: {
              Authorization: userState.jwt,
              "content-type": "application/json",
            },
          }
        );
        const parsed = await res.json();
        if (parsed.success) {
          setPendingPayload(undefined);
        }
      } catch {}
    }
  }, [pendingPayload]);

  // marketplace functions

  const acceptBid = useCallback(
    async (bid: Bid) => {
      if (userState === undefined) {
        setAlertMessage("Connect wallet before transacting.");
      } else {
        try {
          const res = await fetch(`${baseUri}/marketplace/acceptbid`, {
            method: "POST",
            headers: {
              Authorization: userState.jwt,
              "content-type": "application/json",
            },
            body: JSON.stringify({
              ...bid,
            }),
          });
          const parsed = await res.json();
          if (parsed.created.uuid) {
            setPendingPayload({
              uuid: parsed.created.uuid,
              state: "created",
              txnId: "",
            });
          }
        } catch {}
      }
    },
    [userState]
  );

  const mintByPrivateListing = useCallback(
    async (sellOffer: string) => {
      if (userState === undefined) {
        setAlertMessage("Connect wallet before transacting.");
      } else {
        try {
          const res = await fetch(`${baseUri}/mint/claim`, {
            method: "POST",
            headers: {
              Authorization: userState.jwt,
              "content-type": "application/json",
            },
            body: JSON.stringify({
              sellOffer: sellOffer,
            }),
          });
          const parsed = await res.json();
          if (parsed.created.uuid) {
            setPendingPayload({
              uuid: parsed.created.uuid,
              state: "created",
              txnId: "",
            });
          }
        } catch {}
      }
    },
    [userState]
  );

  const bidToken = useCallback(
    async (tokenId: string, amount: string, owner: string, expiry: number) => {
      if (userState === undefined) {
        setAlertMessage("Connect wallet before transacting.");
      } else {
        try {
          const res = await fetch(`${baseUri}/marketplace/bidtoken`, {
            method: "POST",
            headers: {
              Authorization: userState.jwt,
              "content-type": "application/json",
            },
            body: JSON.stringify({
              tokenId,
              amount,
              owner,
              expiry: dateToRippleEpoch(expiry),
            }),
          });
          const parsed = await res.json();
          if (parsed.created.uuid) {
            setPendingPayload({
              uuid: parsed.created.uuid,
              state: "created",
              txnId: "",
            });
          }
        } catch {}
      }
    },
    [userState]
  );

  const buyToken = useCallback(
    async (listing: Listing) => {
      if (userState === undefined) {
        setAlertMessage("Connect wallet before transacting.");
      } else {
        try {
          const res = await fetch(`${baseUri}/marketplace/buytoken`, {
            method: "POST",
            headers: {
              Authorization: userState.jwt,
              "content-type": "application/json",
            },
            body: JSON.stringify({
              ...listing,
            }),
          });
          const parsed = await res.json();
          if (parsed.created.uuid) {
            setPendingPayload({
              uuid: parsed.created.uuid,
              state: "created",
              txnId: "",
            });
          }
        } catch {}
      }
    },
    [userState]
  );

  const cancelOffer = useCallback(
    async (offers: Array<Listing | Bid>, tokenId: string) => {
      if (userState === undefined) {
        setAlertMessage("Connect wallet before transacting.");
      } else {
        try {
          const res = await fetch(`${baseUri}/marketplace/canceloffer`, {
            method: "POST",
            headers: {
              Authorization: userState.jwt,
              "content-type": "application/json",
            },
            body: JSON.stringify({ offers, tokenId }),
          });
          const parsed = await res.json();
          if (parsed.created.uuid) {
            setPendingPayload({
              uuid: parsed.created.uuid,
              state: "created",
              txnId: "",
            });
          }
        } catch {}
      }
    },
    [userState]
  );

  const listToken = useCallback(
    async (
      tokenId: string,
      amount: string,
      expiry: number,
      destination?: string
    ) => {
      if (userState === undefined) {
        setAlertMessage("Connect wallet before listing tokens.");
      } else {
        try {
          const res = await fetch(`${baseUri}/marketplace/listtoken`, {
            method: "POST",
            headers: {
              Authorization: userState.jwt,
              "content-type": "application/json",
            },
            body: JSON.stringify({
              tokenId,
              amount,
              expiry: dateToRippleEpoch(expiry),
              destination,
            }),
          });
          const parsed = await res.json();
          if (parsed.created.uuid) {
            setPendingPayload({
              uuid: parsed.created.uuid,
              state: "created",
              txnId: "",
            });
          }
        } catch {}
      }
    },
    [userState]
  );

  //token methods
  const getTokens = useCallback(async (query: any) => {
    try {
      const res = await fetch(`${baseUri}/marketplace/tokens`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...query,
        }),
      });
      const parsed = await res.json();
      if (parsed.results) {
        return parsed.results;
      } else {
        return [];
      }
    } catch {
      return [];
    }
  }, []);

  const getToken = useCallback(async (tokenId: string) => {
    try {
      const res = await fetch(
        `${baseUri}/marketplace/token?tokenId=${tokenId}`
      );
      const parsed = await res.json();
      if (parsed.tokenId) {
        return parsed;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }, []);

  // activity methods

  const getActivity = useCallback(
    async (
      query: {
        sales: boolean;
        listings: boolean;
        offers: boolean;
      },
      after: string
    ) => {
      try {
        const res = await fetch(`${baseUri}/marketplace/activity`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            ...query,
            after,
          }),
        });
        const parsed = await res.json();
        if (parsed.results) {
          return parsed.results;
        } else {
          return [];
        }
      } catch {
        return [];
      }
    },
    []
  );

  //effects
  useEffect(() => {
    if (pendingPayload !== undefined) {
      const intervalId = setInterval(async () => {
        const response = await fetch(
          `${baseUri}/marketplace/getpayload?payload=${pendingPayload.uuid}`,
          {
            headers: {
              Authorization: userState ? userState.jwt : "",
            },
          }
        );
        const parsed = await response.json();
        const payload = {
          uuid: parsed.meta.uuid,
          state: getPayloadState(parsed),
          txnId: "",
        };
        if ("response" in parsed) {
          console.log(parsed.response);
          if (
            "txid" in parsed.response &&
            parsed.response.txid !== null &&
            parsed.response.txid !== ""
          ) {
            payload["txnId"] = parsed.response.txid;
          }
        }
        setPendingPayload(payload);
      }, 3000);

      return () => clearInterval(intervalId);
    }
  }, [pendingPayload]);

  useEffect(() => {
    if (pendingPayload) {
      if (
        pendingPayload.state === "confirmed" ||
        pendingPayload.state === "failed" ||
        pendingPayload.state === "cancelled" ||
        pendingPayload.state === "expired"
      ) {
        setTimeout(() => {
          setPendingPayload(undefined);
        }, 2500);
      }
    }
  }, [pendingPayload]);

  useEffect(() => {
    // after user starts a login session and payload has been added, poll result
    if (pendingLogin !== undefined) {
      const intervalId = setInterval(async () => {
        const response = await fetch(
          `${baseUri}/users/getlogin?payload=${pendingLogin.payload}`
        );
        const parsed = await response.json();
        if (parsed.meta.expired || parsed.meta.cancelled) {
          // if expired clear pendings and stop polling
          setPendingLogin(undefined);
          setIsLoading(false);
        }
        if (parsed.meta.signed && parsed.meta.resolved) {
          // if payload is signed and resolved verify results and receive auth cookie
          const res = await fetch(
            `${baseUri}/users/verify?hex=${parsed.response.hex}&address=${parsed.response.account}&token=${parsed.application.issued_user_token}`
          );
          const parsedRes = await res.json();
          if (parsedRes.jwt) {
            updateUserState({ ...parsedRes });
            setPendingLogin(undefined);
            setIsLoading(false);
          }
        }
      }, 3000);

      return () => clearInterval(intervalId);
    }
  }, [pendingLogin]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserState();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [userState]);

  useEffect(() => {
    // init app on load
    getUserState();
  }, []);

  return {
    userState,
    pendingLogin,
    pendingPayload,
    acceptBid,
    mintByPrivateListing,
    cancelPayload,
    cancelOffer,
    connectWallet,
    disconnectWallet,
    bidToken,
    listToken,
    buyToken,
    getTokens,
    getToken,
    getActivity,
  };
};
