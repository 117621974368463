import { useContext, useEffect, useState } from "react";
import { XummContext } from "../lib/context/xumm";
import { Landing } from "./landing";
import { TokenBody } from "./token";
import { Claim } from "./claim";
import { Account } from "./account";
import { Faq } from "./faq";
import { UserPage } from "./user";
import { Game } from "./game";
import { Page404 } from "./404";
import { Footer } from "../shared/components/footer";
import { DocumentTitle } from "../shared/components/document-title";
import { User } from "../shared/types/user";

export const PageLanding: React.FC = () => {
  return (
    <>
      <DocumentTitle title="Trade | Space Mermaids by Ragzy" />
      <Landing />
      <Footer />
    </>
  );
};

export const PageToken: React.FC = () => {
  return (
    <>
      <DocumentTitle title="Token | Space Mermaids by Ragzy" />
      <TokenBody />
      <Footer />
    </>
  );
};

export const PageClaim: React.FC = () => {
  return (
    <>
      <DocumentTitle title="Claim | Space Mermaids by Ragzy" />
      <Claim />
      <Footer />
    </>
  );
};

export const PageAccount: React.FC = () => {
  const [userData, setUserData] = useState<null | User>(null);
  const { userState } = useContext(XummContext);

  const getUserData = async (address: string) => {
    const res = await fetch(
      `https://api.spacemermaids.xyz/marketplace/user?address=${address}`
    );
    const parsed = await res.json();
    setUserData(parsed);
  };

  useEffect(() => {
    if (userState && userState.user.userAddress) {
      getUserData(userState.user.userAddress);
    }
  }, [userState]);
  return (
    <>
      <DocumentTitle title="Account | Space Mermaids by Ragzy" />
      <Account data={userData} />
      <Footer />
    </>
  );
};

export const PageFaq: React.FC = () => {
  return (
    <>
      <DocumentTitle title="FAQ | Space Mermaids by Ragzy" />
      <Faq />
      <Footer />
    </>
  );
};

export const PageUser: React.FC = () => {
  return (
    <>
      <DocumentTitle title="User | Space Mermaids by Ragzy" />
      <UserPage />
      <Footer />
    </>
  );
};

export const PageGame: React.FC = () => {
  return (
    <>
      <DocumentTitle title="Play | Space Mermaids by Ragzy" />
      <Game />
      <Footer />
    </>
  );
};

export const PageNotFound: React.FC = () => {
  return (
    <>
      <DocumentTitle title="Not Found" />
      <Page404 />
    </>
  );
};
