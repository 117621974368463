//@ts-ignore
import safemath from "safemath";

export const fromXrpDrops = (amount: string | number) => {
  return safemath.safeDiv(amount, "1000000").toFixed(2);
};

export const toXrpDrops = (amount: string) => {
  return safemath.safeMule(amount, "1000000").toString();
};
