import { useContext } from "react";
import { XummContext } from "../../../lib/context/xumm";
import placeholder from "../../../assets/prereveal.gif";
import { NavLink } from "react-router-dom";

type MermaidCardProps = {
  mermaid: { offer: string; claimed: boolean };
  setTxModalOpen: (value: boolean) => void;
};

export const MermaidCard: React.FC<MermaidCardProps> = ({
  mermaid,
  setTxModalOpen,
}) => {
  const { mintByPrivateListing } = useContext(XummContext);

  const onClaimHandler = () => {
    //@ts-ignore
    mintByPrivateListing(mermaid.offer).then(setTxModalOpen(true));
  };

  return (
    <div className="site--claim-mermaid-card">
      <div className="site--claim-mermaid-card-img-text">
        <img
          src={placeholder}
          height={100}
          alt="space mermaid"
          className="site--claim-mermaid-card-image"
        />
        <span>Space Mermaid #???</span>
      </div>
      <div className="site--claim-section-claim-wrapper-button">
        <button
          className="site--claim-section-claim-wrapper-claim"
          onClick={onClaimHandler}
          type="button"
          disabled={mermaid.claimed}
        >
          {mermaid.claimed ? "Claimed!" : "Claim Mermaid"}
        </button>
      </div>
    </div>
  );
};
