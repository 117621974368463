import { useEffect } from "react";

type DocumentTitleProps = {
  title: string;
};

export const DocumentTitle: React.FC<DocumentTitleProps> = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return null;
};
