import { useState, useEffect, useCallback } from "react";
import { UserCard } from "./user-card";
import { IconArrow } from "../../../icons";
import { toAddressSubstring } from "../../../lib/helpers";
import { useWindowDimension } from "../../../hooks/useWindow";

const baseUri = "https://api.spacemermaids.xyz";

type LeaderBoardProps = {
  userState: any;
  userGameState: any;
  setLoginModalOpen: () => void;
};

export const Leaderboard: React.FC<LeaderBoardProps> = ({
  userState,
  userGameState,
  setLoginModalOpen,
}) => {
  const { width } = useWindowDimension();
  const [leaderboard, setLeaderboard] = useState<
    | undefined
    | Array<{ username: string; userAddress: string; highestScore: number }>
  >();

  const [index, setIndex] = useState(0);
  const [isNext, setIsNext] = useState(true);
  const [scoresSum, setScoresSum] = useState<undefined | number>(undefined);

  const getLeaderboard = async (index: any) => {
    try {
      const res = await fetch(`${baseUri}/play/scores?limit=10&index=${index}`);
      const parsed = await res.json();
      if (parsed.success) {
        setLeaderboard([...parsed.results]);
        setIsNext(parsed.isNext);
        setScoresSum(parsed.sumScores);
      }
    } catch {}
  };

  // get leaderboard on initial page load and on index changes
  useEffect(() => {
    getLeaderboard(index);
  }, [index]);

  // poll leaderboard for new updates every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      getLeaderboard(index);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [index]);

  const onPageUpHandler = () => {
    setIndex((currentIndex) => {
      return currentIndex + 10;
    });
  };

  const onPageDownHandler = () => {
    if (index !== 0) {
      setIndex((currentIndex) => {
        return currentIndex - 10;
      });
    }
  };

  const pages = () => {
    if (index === 0 && !isNext) {
      return [1];
    } else if (index === 0 && isNext) {
      return [1, 2];
    } else if (!isNext) {
      return [index / 10, index / 10 + 1];
    } else {
      return [index / 10, index / 10 + 1, index / 10 + 2];
    }
  };

  const getLeaderboardField = useCallback(
    (entry: { userAddress: string; username: string }) => {
      if (entry.userAddress === userState?.user.userAddress) {
        return "You";
      }
      if (entry.username !== "" && entry.username !== null) {
        return entry.username;
      }
      return toAddressSubstring(entry.userAddress);
    },
    [userState]
  );

  return (
    <div className="site--game-leaderboard-wrapper">
      {width > 910 && (
        <UserCard
          userState={userState}
          gameUser={userGameState}
          setLoginModalOpen={setLoginModalOpen}
        />
      )}
      <div className="site--game-leaderboard-header">
        <span className="site--game-leaderboard-header-primary">
          Leaderboard
        </span>
        {scoresSum !== undefined && (
          <span className="site--game-leaderboard-header-secondary">
            {scoresSum} / 50000
          </span>
        )}
      </div>
      <div className="site--game-leaderboard-table-wrapper">
        {leaderboard?.map((entry, value) => {
          const isUsersScore =
            entry.userAddress === userState?.user.userAddress;
          const entryClassName = isUsersScore
            ? "site--game-leaderboard-table-entry users-score"
            : "site--game-leaderboard-table-entry";
          return (
            <div className={entryClassName}>
              <div className="site--game-leaderboard-table-rank">
                <span>{index === 0 ? value + 1 : index + value + 1}.</span>
                <span className="site--game-leaderboard-table-entry-userid">
                  {getLeaderboardField(entry)}
                </span>
              </div>

              <span className="site--game-leaderboard-table-entry-score">
                {entry.highestScore}
              </span>
            </div>
          );
        })}
      </div>
      <div className="site--game-leaderboard-table-pagination">
        <button
          className="site--game-leaderboard-table-pagination-button prev"
          onClick={onPageDownHandler}
          disabled={index === 0}
        >
          <IconArrow /> Prev
        </button>
        <div className="site--game-leaderboard-table-pagination-pages">
          {pages().map((page) => {
            const isCurrentPage = index / 10 + 1 === page;
            return (
              <span
                onClick={() => setIndex((page - 1) * 10)}
                className={
                  isCurrentPage
                    ? "pagination-num current-page"
                    : "pagination-num"
                }
              >
                {page}
              </span>
            );
          })}
        </div>
        <button
          className="site--game-leaderboard-table-pagination-button next"
          onClick={onPageUpHandler}
          disabled={!isNext}
        >
          Next <IconArrow />
        </button>
      </div>
    </div>
  );
};
