import { useState, useEffect } from "react";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "../../../modal";
import { Token } from "../../../../types";

type ClaimModalProps = {
  tokens: Array<Token>;
  open: boolean;
  closeModal: () => void;
  setProfileImage: (url: string) => void;
};

export const ClaimModal: React.FC<ClaimModalProps> = ({
  tokens,
  open,
  closeModal,
  setProfileImage,
}) => {
  return (
    <Modal open={open} closeModal={closeModal}>
      <ModalHeader closeModal={closeModal}>
        <span>Claim Your Profile Picture</span>
      </ModalHeader>
      <ModalContent>
        <div className="claim-profile-modal-content">
          {tokens.map((token) => {
            return (
              <button
                type="button"
                onClick={() => setProfileImage(token.image)}
                className="claim-profile-image-select-button"
              >
                <img src={token.image} height={80} width={80} />
              </button>
            );
          })}
        </div>
      </ModalContent>
      <ModalFooter>
        <div className="list-token-modal-footer">
          <button
            className="product-header-token-actions-button islight wide"
            onClick={closeModal}
          >
            Done
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
